import { Box, CSSObject, ListItem, ListItemButton, ListItemIcon, ListItemText, styled, Theme } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAppSelector } from "../lib/hook";
import { authSelector } from "../lib/ducks/authDuck";
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import SettingsIcon from '@mui/icons-material/Settings';
import FeedbackIcon from '@mui/icons-material/Feedback';
import HelpIcon from '@mui/icons-material/Help';
import ForumIcon from '@mui/icons-material/Forum';
import CPlusIcon from '../assets/images/cplus.svg'


const OpenedMixin = (theme: Theme, drawerWidthMax: number): CSSObject => ({
    width: drawerWidthMax,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    border: "none"
});

const closedMixin = (theme: Theme, drawerwidthmin: number): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: drawerwidthmin,
    border: "none"
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })<MyDrawerProps>(
    ({ theme, open, drawerwidthmin, drawerwidthmax }) => ({
        width: drawerwidthmax,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...OpenedMixin(theme, drawerwidthmax),
            '& .MuiDrawer-paper': OpenedMixin(theme, drawerwidthmax),
        }),
        ...(!open && {
            ...closedMixin(theme, drawerwidthmin),
            '& .MuiDrawer-paper': closedMixin(theme, drawerwidthmin),
        }),
    }),
);

interface MyDrawerProps extends DrawerProps {
    drawerwidthmin: number,
    drawerwidthmax: number
}

interface ComponentProps {
    drawerWidthMin: number,
    drawerWidthMax: number
    handleNewChat: any,
    handleDrawerOpen: any,
    handleDrawerClose: any,
    handleFeedback: any
}

export const NavigationDrawer: React.FC<ComponentProps> = ({ drawerWidthMin, drawerWidthMax, handleNewChat, handleDrawerOpen, handleDrawerClose, handleFeedback }) => {
    const navigate = useNavigate()
    const [navigationDrawerOpen, setNavigationDrawerOpen] = useState(false);
    const darkTheme = useAppSelector(authSelector).darkTheme

    return (
        <Drawer drawerwidthmax={drawerWidthMax} drawerwidthmin={drawerWidthMin} variant="permanent"
            open={navigationDrawerOpen}
            //open={true}
            sx={{ borderRight: '0px', position: 'fixed', height: '100vh', zIndex: 1300 }}>
            <Box sx={{ height: '100vh', minWidth: '80px', backgroundColor: '#4E657E' }}
                display={'flex'} flexDirection={'column'} alignItems={'center'} paddingTop={2} flexGrow={1}
                onMouseLeave={() => setNavigationDrawerOpen(false)}
            >
                <ListItem disablePadding sx={{ display: 'block', paddingLeft: '23px' }} onMouseOver={() => setNavigationDrawerOpen(true)}>
                    <Link to='/' onClick={() => { handleNewChat(); handleDrawerOpen() }}>
                        {navigationDrawerOpen ?
                            <img
                                src={"/images/codex_logo_white.png"}
                                height={'30px'}
                                width={'auto'}
                            />
                            :
                            <img
                                src={darkTheme ? CPlusIcon : CPlusIcon}
                                height={'30px'}
                                width={'auto'}
                            />
                        }
                    </Link>
                </ListItem>
                <ListItem disablePadding sx={{ display: 'block', marginTop: '50px', }} onMouseOver={() => setNavigationDrawerOpen(true)}>
                    <ListItemButton
                        onClick={() => { navigate('/'); handleDrawerOpen() }}
                        sx={{
                            minHeight: 48,
                            justifyContent: navigationDrawerOpen ? 'initial' : 'center',
                            //px: 2.5,
                            margin: '20px',
                            borderRadius: '8px',
                            px: '11px'
                        }}

                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: navigationDrawerOpen ? '11px' : 0,
                                justifyContent: 'center',
                            }}
                        >
                            <ForumIcon sx={{ color: '#F8F8F8' }} />
                        </ListItemIcon>
                        {navigationDrawerOpen &&
                            <ListItemText primary={'Codex+'} primaryTypographyProps={{
                                fontSize: 14,
                                color: '#F8F8F8',
                            }} />
                        }
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding sx={{ display: 'block', marginTop: 'auto', }} onMouseOver={() => setNavigationDrawerOpen(true)}                        >
                    <ListItemButton
                        onClick={() => { navigate('/info'); handleDrawerClose() }}
                        sx={{
                            minHeight: 48,
                            justifyContent: navigationDrawerOpen ? 'initial' : 'center',
                            px: '11px',
                            margin: '10px 20px',
                            borderRadius: '8px'
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: navigationDrawerOpen ? '11px' : 0,
                                justifyContent: 'center',


                            }}
                        >
                            <HelpIcon sx={{ color: '#F8F8F8' }} />
                        </ListItemIcon>
                        {navigationDrawerOpen &&
                            <ListItemText primary={'Ohje'} primaryTypographyProps={{
                                fontSize: 14,
                                color: '#F8F8F8',
                            }} />
                        }
                    </ListItemButton>
                </ListItem>
                {/*
                <ListItem disablePadding sx={{ display: 'block', }} onMouseOver={() => setNavigationDrawerOpen(true)}                        >
                    <ListItemButton
                        onClick={() => { navigate('/settings'); handleDrawerClose() }}
                        sx={{
                            minHeight: 48,
                            justifyContent: navigationDrawerOpen ? 'initial' : 'center',
                            px: 2.5,
                            margin: '10px 20px',
                            borderRadius: '8px'
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: navigationDrawerOpen ? 3 : 0,
                                justifyContent: 'center',


                            }}
                        >
                            <SettingsIcon sx={{ color: '#F8F8F8' }} />
                        </ListItemIcon>
                        {navigationDrawerOpen &&
                            <ListItemText primary={'Asetukset'} primaryTypographyProps={{
                                fontSize: 14,
                                color: '#F8F8F8',
                            }} />
                        }
                    </ListItemButton>
                </ListItem>

                */}
                <ListItem disablePadding sx={{ display: 'block' }} onMouseOver={() => setNavigationDrawerOpen(true)}                        >
                    <ListItemButton
                        onClick={handleFeedback}
                        sx={{
                            minHeight: 48,
                            justifyContent: navigationDrawerOpen ? 'initial' : 'center',
                            px: '11px',
                            margin: '20px',
                            borderRadius: '8px'
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: navigationDrawerOpen ? '11px' : 0,
                                justifyContent: 'center',


                            }}
                        >
                            <FeedbackIcon sx={{ color: '#F8F8F8' }} />
                        </ListItemIcon>
                        {navigationDrawerOpen &&
                            <ListItemText primary={'Lähetä palautetta'} primaryTypographyProps={{
                                fontSize: 14,
                                color: '#F8F8F8',
                            }} />
                        }
                    </ListItemButton>
                </ListItem>
            </Box>
        </Drawer>
    );

}